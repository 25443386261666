// ref: https://gitlab.com/web-apps-dietela/backend/-/merge_requests/302

const header = async(axios) => {
  return axios.get('/v1/general/program-konsultasi/header')
}
const layananUtama = async(axios) => {
  return axios.get('/v1/general/program-konsultasi/layanan-utama')
}
const otherProblems = async(axios) => {
  return axios.get('/v1/general/program-konsultasi/other-problems')
}
export const reqProgramKonsultasi = {
  header,
  layananUtama,
  otherProblems,
}